<template>
  <div class="count-down">
    <div class="down-time">
      <p class="ty-down">{{ dataTime? dataTime.day : "0" }}</p>
      <span style="font-size: 20px; margin: 0 8px">D</span>
      <p class="ty-down">{{ dataTime ? dataTime.hour : "00" }}</p>
      <span style="font-size: 20px; margin: 0 8px">:</span>
      <p class="ty-down">{{ dataTime ? dataTime.min : "00" }}</p>
      <span style="font-size: 20px; margin: 0 8px">:</span>
      <p class="ty-down">{{ dataTime ? dataTime.second : "00" }}</p>
    </div>
  </div>
</template>
<script>
import countTimeFun from "@/utils/showTime";
export default {
  props: {
    itemTime: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dataTime: {},
    };
  },
  mounted() {
    // console.log(this.itemTime);
    countTimeFun(this.TimeNum, this.itemTime);
  },
  methods: {
    //时间计算
    TimeNum(val) {
      this.dataTime = val;
      return val;
    },
  },
  created() {
  },
};
</script>
<style lang="scss" scoped>
p {
  margin: 0;
}
.count-down {
  height: 200px;
  background: url("http://image.matchupexpo.com/8cd4c6eb854a41d6b590c10507052f28.png");
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  .down-time {
    width: 446px;
    height: 110px;
    background: #3b234b;
    opacity: 0.9;
    border-radius: 10px;
    // text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 15px;
    .ty-down {
      width: 65px;
      height: 59px;
      background: #ffffff;
      border-radius: 5px;
      color: #000;
      font-size: 39px;
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>

<template>
  <div>
    <a-modal
      v-model="visible"
      :footer="null"
      :centered="true"
      :width="726"
      @cancel="handleCancel"
    >
      <p class="modal-top">Sign Up Now</p>
      <p class="ty-modal-tit">
        <span>Fill in the information:</span>
        <a
          href="https://image.matchupexpo.com/1dc1f774eb024abaa977122def90dcfb.mp4" target="_blank"
        >
          <span style="margin-left: 55px"
            >Haz clic el link para consultar la guía</span
          >
        </a>
      </p>
      <div class="modal-mid-box">
        <a-form-model
          :model="signModalForm"
          ref="signModalForm"
          :rules="modalRules"
        >
          <a-form-model-item label="Country" prop="country">
            <a-select v-model="signModalForm.country" @change="handleChange">
              <a-select-option
                v-for="country in countryData"
                :key="country.regCode"
                :value="country.regCode"
              >
                {{ country.enName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Company Name" prop="companyNameEn">
            <a-input v-model="signModalForm.companyNameEn" />
          </a-form-model-item>
          <a-form-model-item label="E-mail" prop="email">
            <a-input v-model="signModalForm.email" />
          </a-form-model-item>
          <a-form-model-item
            label="Mobile Number"
            class="phone"
            prop="phoneNumber"
          >
            <a-input
              :addon-before="
                signModalForm.areaCode ? '+' + signModalForm.areaCode : ''
              "
              v-model="signModalForm.phoneNumber"
            />
            <!-- <a-input
              :addon-before="signModalForm.areaCode"
              v-model="signModalForm.phoneNumber"
            /> -->
          </a-form-model-item>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="First Name" prop="firstName">
                <a-input v-model="signModalForm.firstName" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                label="Last Name"
                style="margin-left: 30px"
                prop="lastName"
              >
                <a-input v-model="signModalForm.lastName" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <p class="ty-modal-tit">Industry selection:</p>
      <div class="selection-box">
        <a-checkbox-group v-model="checkedList">
          <a-checkbox
            v-for="check in checkData"
            :key="check.value"
            :value="check.value"
          >
            {{ check.label }}
          </a-checkbox>
        </a-checkbox-group>
      </div>
      <a-button class="sign-btn" @click="signClick" :loading="loadingBtn"
        >Sign up now</a-button
      >
      <!-- <div class="sign-btn" @click="signClick">Sign up now</div> -->
    </a-modal>
  </div>
</template>
<script>
import { getChannel } from "@/utils/auth";
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // 邮箱
    let valiedateEmail = (rule, value, callback) => {
      const reg =
        /^([a-zA-Z0-9\S]{1,})+\@+(([a-zA-Z0-9\S]{1,})\.([a-zA-Z0-9\S]{1,})|([a-zA-Z0-9\S]{1,})\.([a-zA-Z0-9\S]{1,})+(\S){1,})$/;
      // const reg = /^[^@].*[^@]$/;
      if (!value) {
        callback(new Error("Please fill in the E-mail correctly"));
      } else if (!reg.test(value)) {
        callback(new Error("Please enter the correct email address"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      visible: this.isShow,
      signModalForm: {
        areaCode: undefined, //区号
        companyNameEn: undefined,
        country: undefined,
        exhibitionId: 263,
        exhibitionSignupType: 1,
        phoneNumber: undefined,
        requirePassword: false,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        goodsCategory: [],
        sourceCode: null,
      },
      // 验证规则
      modalRules: {
        email: [{ required: true, validator: valiedateEmail, trigger: "blur" }],
        country: [
          {
            required: true,
            message: "Please fill in the country",
            trigger: "blur",
          },
        ],
        companyNameEn: [
          {
            required: true,
            message: "Please fill in the companyNameEn",
            trigger: "blur",
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: "Please fill in the phoneNumber",
            trigger: "blur",
          },
        ],
        firstName: [
          {
            required: true,
            message: "Please fill in the firstName",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please fill in the firstName",
            trigger: "blur",
          },
        ],
      },
      countryData: [
        {
          areaCode: "54",
          cnName: "阿根廷",
          enName: "Argentina",
          regCode: "G402",
        },
        {
          areaCode: "591",
          cnName: "玻利维亚",
          enName: "Bolivia",
          regCode: "G408",
        },
        {
          regCode: "G410",
          cnName: "巴西",
          enName: "Brazil",
          areaCode: "55",
        },
        {
          regCode: "G412",
          cnName: "智利",
          enName: "Chile",
          areaCode: "56",
        },
        {
          regCode: "G413",
          cnName: "哥伦比亚",
          enName: "Colombia",
          areaCode: "57",
        },
        {
          regCode: "G419",
          cnName: "厄瓜多尔",
          enName: "Ecuador",
          areaCode: "593",
        },
        {
          regCode: "G424",
          cnName: "圭亚那",
          enName: "Guyana",
          areaCode: "592",
        },
        {
          regCode: "G433",
          cnName: "巴拉圭",
          enName: "Paraguay",
          areaCode: "595",
        },
        {
          regCode: "G434",
          cnName: "秘鲁",
          enName: "Peru",
          areaCode: "51",
        },
        {
          regCode: "G441",
          cnName: "苏里南",
          enName: "Suriname",
          areaCode: "597",
        },
        {
          regCode: "G444",
          cnName: "乌拉圭",
          enName: "Uruguay",
          areaCode: "598",
        },
        {
          regCode: "G445",
          cnName: "委内瑞拉",
          enName: "Venezuela",
          areaCode: "58",
        },
      ],
      checkedList: [],
      checkData: [
        { label: " Smart Home", value: 538 },
        { label: " Electric & Lighting", value: 539 },
        { label: " Hardware & Building Materials", value: 540 },
        { label: " Auto & Motocycle Parts", value: 541 },
        { label: " Consumer Electronics", value: 542 },
        { label: " Textiles", value: 543 },
        { label: " Machinery & Accessory", value: 546 },
        { label: " Others", value: 547 },
      ],
      loadingBtn: false,
    };
  },
  watch: {
    isShow(val) {
      this.visible = val;
      if (val) {
        this.checkedList = [];
        this.signModalForm = Object.assign(this.signModalForm, {
          areaCode: undefined, //区号
          companyNameEn: undefined,
          country: undefined,
          exhibitionId: 263,
          exhibitionSignupType: 1,
          phoneNumber: undefined,
          requirePassword: false,
          firstName: undefined,
          lastName: undefined,
          email: undefined,
          goodsCategory: [],
          sourceCode: null,
        });
      }
    },
  },
  methods: {
    signClick() {
      this.$refs.signModalForm.validate((valid) => {
        if (valid) {
          if (this.checkedList.length) {
            this.signModalForm.goodsCategory = this.checkedList.toString();
            this.signModalForm.sourceCode = getChannel();
            this.loadingBtn = true;
            this.$store
              .dispatch("userRegister", this.signModalForm)
              .then((data) => {
                this.loadingBtn = false;
                this.$emit("isShowSuccessfully", data.data.email);
              })
              .catch(() => {
                this.loadingBtn = false;
              });
          } else {
            this.$message.error("Please select category");
          }
        }
      });
    },
    handleChange(val) {
      this.countryData.forEach((item) => {
        if (item.regCode === val) {
          this.signModalForm.areaCode = item.areaCode;
        }
      });
    },
    handleCancel() {
      this.$refs.signModalForm.clearValidate();
      this.$emit("handleCancel");
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
/deep/.ant-btn {
  border: none;
}
/deep/.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  background: #09b8f2;
  color: #fff;
}
/deep/.ant-modal-content {
  border-radius: 10px;
}
p {
  margin: 0;
}
.modal-top {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
.ty-modal-tit {
  font-size: 18px;
  font-weight: bold;
  padding-left: 80px;
  margin: 20px 0 15px 0;
}
.modal-mid-box {
  width: 602px;
  // height: 413px;
  background: #fafdfe;
  border: 2px solid #52abe5;
  border-radius: 10px;
  margin: 0 auto;
  padding: 5px 20px 25px;
  /deep/.ant-form-item {
    margin: 0;
  }
  .phone {
    /deep/.ant-input-group-addon {
      border: 2px solid #52abe5;
      border-right: none;
      background: #fafdfe;
      width: 80px;
      // padding: 0 35px;
    }
    /deep/.ant-input {
      // height: 55px;
      border: 2px solid #52abe5;
      // color: #fff;
    }
  }
}
.selection-box {
  width: 602px;
  height: 151px;
  background: #fafdfe;
  border: 2px solid #52abe5;
  border-radius: 10px;
  margin: 0 auto;
  padding: 10px 40px;
  /deep/.ant-checkbox-group {
    width: 100%;
  }
  /deep/.ant-checkbox-wrapper {
    width: 50%;
    // color: #fff;
    margin: 5px 0;
  }
}
.sign-btn {
  width: 250px;
  height: 40px;
  line-height: 40px;
  background: #09b8f2;
  border-radius: 20px;
  margin: 20px auto 0 200px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  color: #fff;
  // margin-left: 340px;
  // margin: 20px;
}
</style>

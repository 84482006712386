<template>
  <div class="box">
    <a-back-top>
      <div class="back-top-box">
        <p class="back-icon1"><a-icon type="up" /></p>
        <a-popover placement="left">
          <template slot="content">
            <div class="ty-hover">
              <img
                src="http://image.matchupexpo.com/98437a5e841d4565bbc051a6f148b4d8.png"
                alt=""
                class="ty-hover-img"
              />
              <p>WhatsApp</p>
            </div>
            <div class="ty-hover">WhatsApp：+86-15366106368</div>
          </template>
          <p class="back-icon2"></p>
        </a-popover>
        <a-popover placement="left">
          <template slot="content">
            <div class="ty-hover">
              <img
                src="http://image.matchupexpo.com/26b2cac3b6d34072833b0acae5caa698.png"
                alt=""
                class="ty-hover-img"
              />
              <p>Mailbox</p>
            </div>
            <div class="ty-hover">
              <a href="mailto://service@matchupexpo.com" style="color: #021954"
                >service@matchupexpo.com
              </a>
            </div>
          </template>
          <p class="back-icon3"></p>
        </a-popover>
        <!-- <p class="back-icon3"></p> -->
      </div>
    </a-back-top>
    <div class="min-box">
      <img src="../../assets/logo.png" alt="logo" />
      <div style="display:flex;">
        <!-- <p class="login-btn-bac" style="margin-right: 15px;" @click="isSuccessfully = true">Join</p> -->
        <p class="login-btn-bac" style="margin-right: 15px;" @click="isShow = true">Join</p>
        <p class="login-btn-bac" @click="isLogin = true" v-if="!token">
          Sign in
        </p>
        <a
          class="login-btn-bac"
          v-else
          href="https://buyer.matchupexpo.com/home"
          target="_blank"
          >Member Center</a
        >
      </div>
    </div>
    <div class="bac-box">
      <img
        src="http://image.matchupexpo.com/bb34168a7c8648b095ae54b861010703.png"
        alt=""
        class="banner"
      />
      <div class="middle-box">
        <timeCon :itemTime="itemTime"></timeCon>
        <ul class="ul-box">
          <li>
            <p class="item-inner home-img">
              <span class="li-tit">Smart home</span>
            </p>
          </li>
          <li>
            <p class="item-inner lighting-img">
              <span class="li-tit">Electric & Lighting</span>
            </p>
          </li>
          <li>
            <p class="item-inner consume-img">
              <span class="li-tit">Consumer Electronics</span>
            </p>
          </li>
          <li>
            <p class="item-inner textiles-img">
              <span class="li-tit">Textiles</span>
            </p>
          </li>

          <li>
            <p class="item-inner parts-img">
              <span class="li-tit"
                >Auto & Motocycle <br />
                Parts</span
              >
            </p>
          </li>
          <li>
            <p class="item-inner materials-img">
              <span class="li-tit"
                >Hardware & Building <br />
                Materials</span
              >
            </p>
          </li>
          <li>
            <p class="item-inner accessory-img">
              <span class="li-tit">Machinery & Accessory</span>
            </p>
          </li>
          <li>
            <p class="item-inner others-img">
              <span class="li-tit">Others</span>
            </p>
          </li>
        </ul>
        <div class="circular-box">
          <img
            src="http://image.matchupexpo.com/fce5125ea6cb4ad3a9549642b4d5e793.png"
            alt=""
            style="margin-top: 125px"
            @click="isShow = true"
          />
        </div>
      </div>
      <!-- <img
        src="http://image.matchupexpo.com/34ce0beb6c544639948ef21d916e7fdd.png"
        alt=""
        class="ty-img"
      /> -->
      <!-- <div class="flex-box">
        <div class="flex-l">
          <img :src="videoUrl" alt="" class="video-size" />
        </div>
        <ul class="flex-r">
          <li
            v-for="img in videoData"
            :key="img.id"
            @click="imgClick(img)"
            :active="`isActive${img.id}` === isActive"
          >
            <img :src="img.liImg" alt="" />
          </li>
        </ul>
      </div> -->
      <img
        src="http://image.matchupexpo.com/d8fe69783b7147bba59357e14dabb480.png"
        alt=""
        class="ty-img"
      />
      <div class="product-box">
        <div class="ty-box" v-for="(item, i) in productData" :key="i">
          <p class="ty-top-title">{{ item.name }}</p>
          <div class="carousel-box">
            <a-carousel arrows :dots="false">
              <div
                slot="prevArrow"
                class="custom-slick-arrow"
                style="left: -7px; top: 120px; z-index: 1"
              >
                <img src="../../images/left.png" alt="" />
              </div>
              <div
                slot="nextArrow"
                class="custom-slick-arrow"
                style="right: 0px; top: 120px"
              >
                <img src="../../images/right.png" alt="" />
              </div>
              <div class="ul-box" v-for="(cn, index) in item.cn" :key="index">
                <img
                  v-for="(url, l) in cn.urlData"
                  :key="l"
                  :src="url.img"
                  alt=""
                  class="ul-item"
                  @click="isShow = true"
                />
              </div>
            </a-carousel>
          </div>
        </div>
        <!-- 3D Products -->
        <div class="three-box">
          <p class="three-title">3D Products</p>
          <div class="three-carousel">
            <a-carousel arrows :dots="false">
              <div
                slot="prevArrow"
                class="custom-slick-arrow"
                style="left: -5px; top: 75px; z-index: 1"
              >
                <img
                  src="http://image.matchupexpo.com/353b78f3297e43eda3341fc12178dc4a.png"
                  alt=""
                />
              </div>
              <div
                slot="nextArrow"
                class="custom-slick-arrow"
                style="right: 35px; top: 75px"
              >
                <img
                  src="http://image.matchupexpo.com/bf05204bc1d14e48b1d50c573e15cecd.png"
                  alt=""
                />
              </div>
              <div class="img-list" v-for="(img, i) in threeData" :key="i">
                <!-- <a
                  href="http://www.matchup.test.renrendiaoyu.com/B2B_3d?id=2006221743263749"
                  target="_blank"
                  v-for="(item, l) in img.imgData"
                  :key="l"
                > -->
                <img
                  v-for="(item, l) in img.imgData"
                  :key="l"
                  :src="item.img"
                  alt=""
                  @click="threeItemClick(item)"
                />
                <!-- @click="threeDVisible = true" -->
                <!-- </a> -->
              </div>
            </a-carousel>
          </div>
        </div>
      </div>
      <!-- enterprises -->
      <img
        src="http://image.matchupexpo.com/e4cf999558c743a88776cd9cc8d8442f.png"
        alt=""
        class="ty-img"
      />
      <div class="enterprises-box">
        <a-carousel arrows :dots="false">
          <div
            slot="prevArrow"
            class="custom-slick-arrow"
            style="left: -2px; top: 135px; z-index: 1"
          >
            <img
              src="http://image.matchupexpo.com/9b13638b40e74c7eaf597f834f3fb298.png"
              alt=""
            />
          </div>
          <div
            slot="nextArrow"
            class="custom-slick-arrow"
            style="right: 38px; top: 135px"
          >
            <img
              src="http://image.matchupexpo.com/827a80d95e4d4290bf166a1568bced90.png"
              alt=""
            />
          </div>
          <div
            class="enterprisesData-list"
            v-for="(img, i) in enterprisesData"
            :key="i"
          >
            <img
              v-for="(item, l) in img.imgData"
              :key="l"
              :src="item.img"
              alt=""
            />
          </div>
        </a-carousel>
      </div>
      <!-- Sign Up Now -->
      <img
        src="http://image.matchupexpo.com/2e43dc0ae1984572b91d04a930b6c34f.png"
        alt=""
        class="ty-img"
      />
      <!-- 报名注册 -->
      <signUp @isShowSuccessfully="isShowSuccessfully"></signUp>
      <!-- 报名弹窗 -->
      <signModal
        :isShow="isShow"
        @handleCancel="isShow = false"
        @isShowSuccessfully="isShowSuccessfully"
      ></signModal>
      <!-- 注册成功 -->
      <successfully
        :isSuccessfully="isSuccessfully"
        @successfully="isSuccessfully = false"
        :tipsPhoneNumber="tipsPhoneNumber"
      ></successfully>
      <!-- 登录 -->
      <loginModal
        :isLogin="isLogin"
        @close="isLogin = false"
        @JoinClick="(isLogin = false), (isShow = true)"
      ></loginModal>
      <!-- 3d弹窗 -->
      <!-- <div class="frameset-style">
         <frameset cols="100%" >
          <frame
            src="http://www.matchup.test.renrendiaoyu.com/B2B_3d?id=2006221743263749"
          />
        </frameset>
      </div> -->

      <a-modal
        :visible="threeDVisible"
        :footer="null"
        :width="1200"
        @cancel="threeDVisible = false"
        :centered="true"
      >
        <frameset cols="100%">
          <!-- <frame
            src="https://www.baidu.com/"
          /> -->
          <frame :src="frameUrl" />
        </frameset>
      </a-modal>
    </div>
  </div>
</template>

<script>
import signUp from "./sign_up";
import signModal from "./sign_modal";
import timeCon from "./timeCon";
import successfully from "@/components/successfully";
import loginModal from "@/components/login_index";
import { getChannel, setChannel } from "@/utils/auth";
import { checkSourceCode } from "@/api/user";
export default {
  components: { signUp, timeCon, signModal, successfully, loginModal },
  data() {
    return {
      videoUrl: "",
      videoData: [
        {
          id: 1,
          liImg:
            "http://image.matchupexpo.com/fd601b6a5c5841dfb55979803fc06110.jpg",
          videoImg:
            "http://image.matchupexpo.com/01ead4ad692c4cc9b1791cc70889c345.png",
        },
        {
          id: 2,
          liImg:
            "http://image.matchupexpo.com/72a4383d5f6c4d25a79a63fdf45a118d.png",
          videoImg:
            "http://image.matchupexpo.com/88b6db3cc83d405590d2a9d90354d151.png",
        },
        {
          id: 3,
          liImg:
            "http://image.matchupexpo.com/3eed8496b17d40a0a3b54fae393dd070.png",
          videoImg:
            "http://image.matchupexpo.com/ac040d2a42784318ab18b68da7fc2b23.png",
        },
      ],
      isActive: "isActive",
      productData: [
        {
          name: "Smart Home",
          more: "More 》",
          moreUrl:
            "https://chinasouthamerica.en.matchupexpo.com/productListB2B?exhibitionId=263",
          cn: [
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/06974e3d595e45d2b0bfaa1e45b93b38.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262384&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/f56b513359174fc390d369e0d3d2c9b6.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262846&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/d443310d0efb4f659836db6642ada34f.png", //已改
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358264154&exhibitionId=263",
                },
              ],
            },
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/adbfdbf2bcd24e9c96fac4b0ba3c7096.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262437&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/07ad81980e4c4a0c85f80cf378bd37e4.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358264039&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/6b8c8b33d9b64f79a924bc71311f7537.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262874&exhibitionId=263",
                },
              ],
            },
          ],
        },
        {
          name: "Electric &  Lighting",
          more: "More 》",
          moreUrl:
            "https://chinasouthamerica.en.matchupexpo.com/productListB2B?exhibitionId=263",
          cn: [
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/406f085fcece4a9898a75f6f96aedc95.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358263480&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/19b9a7f8c1cb46a1936719f617b5c3cf.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262147&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/efee76d7b9364821b450f6d177c0afeb.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291357352506&exhibitionId=263",
                },
              ],
            },
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/fc7130fba5954dd79c8d69a18c1eff0d.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262707&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/191dc6645ac5461b91a95917610a834f.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358254639&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/9f72688966f24a90941382d761e34aed.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262204&exhibitionId=263",
                },
              ],
            },
          ],
        },
        {
          name: "Hardware & Building Materials",
          more: "More 》",
          moreUrl:
            "https://chinasouthamerica.en.matchupexpo.com/productListB2B?exhibitionId=263",
          cn: [
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/b497ed41559f4fc79b69c2ed605fcf94.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358263241&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/05a7d63c0c014673b0944ee0f6eecfd2.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262729&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/c2471196ee004eaa9d26a65fa9be64c3.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262822&exhibitionId=263",
                },
              ],
            },
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/010e1da4163c4c8f9e3510f732e89b8f.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358261693&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/16d9b31ece3742979e5fe9966e4a2dbb.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262489&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/9c9f8da34157438eb988a197be491266.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262578&exhibitionId=263",
                },
              ],
            },
          ],
        },
        {
          name: "Auto & Motocycle Parts ",
          more: "More 》",
          moreUrl:
            "https://chinasouthamerica.en.matchupexpo.com/productListB2B?exhibitionId=263",
          cn: [
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/201641ca8a9e47a3ae7f294fffbaf4c2.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358261991&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/460b6860721f49278762e5c243d8c5b0.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262014&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/ad095ba9c167442eaa50befab52626d7.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262931&exhibitionId=263",
                },
              ],
            },
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/41f2c66ec7c94a5b9b28488a0d0a20d6.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262051&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/d833c8522cd14622be34d972617e8e82.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358261986&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/a4ea9061a779466f81f58fe62405bf73.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262048&exhibitionId=263",
                },
              ],
            },
          ],
        },
        {
          name: "Consumer Electronics",
          more: "More 》",
          moreUrl:
            "https://chinasouthamerica.en.matchupexpo.com/productListB2B?exhibitionId=263",
          cn: [
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/3f2774638cba4e0ea13e46dcc8b4589d.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358263510&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/ff7e83bb06f549e195486205297502e7.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358263264&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/01c71ed4e32740ac89e9f93ecabce002.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262330&exhibitionId=263",
                },
              ],
            },
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/6d7001a36140487c852bc2c081fa9040.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262984&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/dcd38f5cc27847ada2451a27d7be2f9a.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358263254&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/17814c0b996a4c86809d8d59df770cfd.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358263508&exhibitionId=263",
                },
              ],
            },
          ],
        },
        {
          name: "Textiles",
          more: "More 》",
          moreUrl:
            "https://chinasouthamerica.en.matchupexpo.com/productListB2B?exhibitionId=263",
          cn: [
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/a6f8dec7b32c40efbc6a9ff22fc3a689.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262279&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/cb9e2e7b00f845cbbb0c6281b743e79d.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262398&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/66c00882a5ed4fe599be66bc811c9ae3.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358263190&exhibitionId=263",
                },
              ],
            },
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/90428a80ece84740abe1ddf7ebad1013.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262060&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/c0610cecb83f416fb770c09486595a65.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262303&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/78e6a194ecf54555bc753ac3ebb4cc35.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262591&exhibitionId=263",
                },
              ],
            },
          ],
        },
        {
          name: "Machinery & Accessory ",
          more: "More 》",
          moreUrl:
            "https://chinasouthamerica.en.matchupexpo.com/productListB2B?exhibitionId=263",
          cn: [
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/3b4eae57cf0c418cb6df7e6055cf05fd.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262976&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/4d90000a13414779b051af651fa16d51.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358263015&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/5ec52e415c794102a2d73eaf3c7d8fa5.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358264334&exhibitionId=263",
                },
              ],
            },
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/12e3a979b9a9431ea5c154216b1b440a.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262525&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/d2a509c5eca143de8bd84ed1c87c3de8.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262688&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/fd52eb7047fd449fae59eca98877ead0.png",
                  url: "https://guangdonghardware.en.matchupexpo.com/productDetailB2B?id=2006291358242096&exhibitionId=202",
                },
              ],
            },
          ],
        },
        {
          name: "Others",
          more: "More 》",
          moreUrl:
            "https://chinasouthamerica.en.matchupexpo.com/productListB2B?exhibitionId=263",
          cn: [
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/4e68a0efc4304f3a91b7c4880efaad1a.png",
                  url: "https://vietnamwx.en.matchupexpo.com/productDetailB2B?id=2006291358232033&exhibitionId=221",
                },
                {
                  img: "http://image.matchupexpo.com/5a233e295a9446b28680b6e821b0fa33.png",
                  url: "https://vietnamwx.en.matchupexpo.com/productDetailB2B?id=2006291358259064&exhibitionId=221",
                },
                {
                  img: "http://image.matchupexpo.com/6d0fa2bba4f84645a7346c49c4816c06.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262380&exhibitionId=263",
                },
              ],
            },
            {
              urlData: [
                {
                  img: "http://image.matchupexpo.com/29c86f3606bb479bb27f0363eb750c14.png",
                  url: "https://chinasouthamerica.en.matchupexpo.com/productDetailB2B?id=2006291358262426&exhibitionId=263",
                },
                {
                  img: "http://image.matchupexpo.com/46536c7f6ed94c9eb6b48a344cf8cd05.png",
                  url: "https://asean.en.matchupexpo.com/productDetailB2B?id=2006291358259633&exhibitionId=236",
                },
                {
                  img: "http://image.matchupexpo.com/e9fc6a5fb7b846338a2de97332b574a4.png",
                  url: "https://asean.en.matchupexpo.com/productDetailB2B?id=2006291358259291&exhibitionId=236",
                },
              ],
            },
          ],
        },
      ],
      threeData: [
        {
          imgData: [
            {
              img: "http://image.matchupexpo.com/0b119477dba34408950da29dbf82c33d.png",
              url: "https://www.matchupexpo.com/B2B_3d?id=2006161437532516",
            },
            {
              img: "http://image.matchupexpo.com/8046032f6d8b4b85be9015b7c2bed46d.png",
              url: "https://www.matchupexpo.com/B2B_3d?id=2006161702083692",
            },
            {
              img: "http://image.matchupexpo.com/d449a61cf28640bfb7ca306e4fe14cbc.png",
              url: "https://www.matchupexpo.com/B2B_3d?id=2006291358256497",
            },
            {
              img: "http://image.matchupexpo.com/fb7a28098d224f27a908e968d257191f.png",
              url: "https://www.matchupexpo.com/B2B_3d?id=2006221331205005",
            },
            {
              img: "http://image.matchupexpo.com/75a839fa095140728e6ac87838b53ee8.png",
              url: "https://www.matchupexpo.com/B2B_3d?id=2006291357352802",
            },
            {
              img: "http://image.matchupexpo.com/be3699af81bd4679bf223078dc37608b.png",
              url: "https://www.matchupexpo.com/B2B_3d?id=2006291357349167",
            },
          ],
        },
        {
          imgData: [
            {
              img: "http://image.matchupexpo.com/023fdd443caf49e3bbfdc25d13c4cfb8.png",
              url: "https://www.matchupexpo.com/B2B_3d?id=2006291357350846",
            },
            {
              img: "http://image.matchupexpo.com/a7e4de0f42e147b19b37fa7761db2ba5.png",
              url: "https://www.matchupexpo.com/B2B_3d?id=2006291357356645",
            },
            {
              img: "http://image.matchupexpo.com/0ac15ef8b427483f840b9a168133104b.png",
              url: "https://www.matchupexpo.com/B2B_3d?id=2006291357351283",
            },
            {
              img: "http://image.matchupexpo.com/a289de5d9fa84017bab0686a63a06ffd.png",
              url: "https://www.matchupexpo.com/B2B_3d?id=2006291357356672",
            },
            {
              img: "http://image.matchupexpo.com/e4ef7ad7098e44b0975745f390569afb.png",
              url: "https://www.matchupexpo.com/B2B_3d?id=2006291358262610",
            },
            {
              img: "http://image.matchupexpo.com/81ac4a172ce947dcb451dcec5369f329.png",
              url: "https://www.matchupexpo.com/B2B_3d?id=2006291358248659",
            },
          ],
        },
      ],
      enterprisesData: [
        {
          imgData: [
            {
              img: "http://image.matchupexpo.com/8bbe1a5a5485411383415e175501f025.png",
            },
            {
              img: "http://image.matchupexpo.com/1a2445bae3a24084b5b05e0ad43d6d6a.png",
            },
            {
              img: "http://image.matchupexpo.com/d7565203792e4d1ca3b66356c06e3b39.png",
            },
            {
              img: "http://image.matchupexpo.com/827e5db5e65442a8852aed09a3ce1529.png",
            },
            {
              img: "http://image.matchupexpo.com/c996191accc04f7bba5ff7200cd09c1a.png",
            },
          ],
        },
        {
          imgData: [
            {
              img: "http://image.matchupexpo.com/ec5b61dcf4b8462fbce7040fc070c16d.png",
            },
            {
              img: "http://image.matchupexpo.com/50bebe7c0bed4c00bb3724f45c8ff3f0.png",
            },
            {
              img: "http://image.matchupexpo.com/6089b2d7707f4474a9eafe75dfb2a41c.png",
            },
            {
              img: "http://image.matchupexpo.com/136629b7d4584137a523cb7171887059.png",
            },
            {
              img: "http://image.matchupexpo.com/232373395c1e447585b30d1f410fb7bd.png",
            },
          ],
        },
      ],
      signForm: {},
      checkedList: [],
      itemTime: "2021-06-21 00:00:00",
      isShow: false, //报名注册弹窗
      isSuccessfully: false, //跳转个人中心
      isLogin: false, //登录弹窗
      tipsPhoneNumber: "", //提示账号
      threeDVisible: false,
      frameUrl: "",
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
  },
  methods: {
    threeItemClick(val) {
      console.log(val);
      this.frameUrl = val.url;
      this.threeDVisible = true;
    },
    isShowSuccessfully(val) {
      this.isSuccessfully = true;
      this.isShow = false;
      this.tipsPhoneNumber = val;
    },
    imgClick(item) {
      this.isActive = `isActive${item.id}`;
      this.videoUrl = item.videoImg;
    },
  },
  created() {
    this.isActive = `isActive${this.videoData[0].id}`;
    this.videoUrl = this.videoData[0].videoImg;
  },
  mounted() {
    if (this.$route.query.l) {
      setChannel(this.$route.query.l);
      checkSourceCode({ sourceCode: getChannel() }).then((res) => {
        if (!res.data) {
          this.$router.push({ name: "404" });
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
/deep/.ant-modal-body {
  // padding: 30px 0 0;
  height: 910px;
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
    height: 7px;
  }
}
.ty-hover {
  display: flex;
  align-items: center;
  color: #021954;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  .ty-hover-img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
}
/deep/.ant-back-top {
  right: 45px;
  bottom: 220px;
}
p {
  margin: 0;
}
.box {
  width: 100%;
  height: 100%;
  .back-top-box {
    width: 60px;
    height: 200px;
    // background: skyblue;
    .back-icon1 {
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 25px;
      background: #fff;
      opacity: 0.3;
      border-radius: 5px;
    }
    .back-icon2 {
      height: 60px;
      background: url("http://image.matchupexpo.com/d358947f28ce4a63b7530740a8c82b64.png")
        no-repeat;
      margin: 10px 0;
      &:hover {
        background: url("http://image.matchupexpo.com/98437a5e841d4565bbc051a6f148b4d8.png")
          no-repeat;
      }
    }
    .back-icon3 {
      height: 60px;
      background: url("http://image.matchupexpo.com/e25db8004b844e3eb08676c669a6f7de.png")
        no-repeat;
      &:hover {
        background: url("http://image.matchupexpo.com/26b2cac3b6d34072833b0acae5caa698.png")
          no-repeat;
      }
    }
  }
  .min-box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    .login-btn-bac {
      color: #fff;
      background: #f4483a;
      border-radius: 6px;
      padding: 5px 10px;
      cursor: pointer;
      // line-height: 35px;
      // text-align: center;
    }
    .min-mid {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333333;
      padding-top: 20px;

      .min-tit {
        font-size: 18px;
      }
    }
  }
  .bac-box {
    background: url("http://image.matchupexpo.com/55caff596d89412fb0258ad53db20e93.png")
      no-repeat;
    padding-bottom: 80px;
    .banner {
      width: 100%;
    }
    .middle-box {
      width: 1200px;
      margin: 0 auto;
      // .count-down {
      //   height: 200px;
      //   background: url("http://image.matchupexpo.com/e553101ce4ea45608d58040af3cdc250.png");
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
      //   padding: 15px 0;
      //   .down-time {
      //     width: 446px;
      //     height: 110px;
      //     background: #3b234b;
      //     opacity: 0.9;
      //     border-radius: 10px;
      //     // text-align: center;
      //     color: #fff;
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     // margin-top: 15px;
      //     .ty-down {
      //       width: 65px;
      //       height: 59px;
      //       background: #ffffff;
      //       border-radius: 5px;
      //     }
      //   }
      // }
      .ul-box {
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 290px;
          float: left;
          box-sizing: border-box;
          font-size: 18px;
          // font-weight: bold;
          margin-bottom: 10px;
          &:hover {
            .item-inner {
              // cursor: pointer;
              background-size: 300px 120px;
            }
          }
          .item-inner {
            height: 120px;
            position: relative;
            display: flex;
            border-radius: 10px;
            box-sizing: border-box;
            justify-content: space-between;
            background: #fff no-repeat center right;
            background-size: 360px 140px;
            transition: background-size 0.3s;
            .li-tit {
              margin: 15px 0 0 10px;
            }
          }
          .home-img {
            background-image: url("http://image.matchupexpo.com/5d52681c205d4c709e70ebcdc1b48905.png");
          }
          .lighting-img {
            background-image: url("http://image.matchupexpo.com/d0bf4f8df5c6454182e41940c7bd4df7.png");
          }
          .materials-img {
            background-image: url("http://image.matchupexpo.com/63c99a9732014c5abe57260663615d92.png");
          }
          .parts-img {
            background-image: url("http://image.matchupexpo.com/14d3b600eb52400da1a39438986732cc.png");
          }
          .consume-img {
            background-image: url("http://image.matchupexpo.com/418b7ca6be2c4cf4b4f82eea98aa6caa.png");
          }
          .textiles-img {
            background-image: url("http://image.matchupexpo.com/71d2dcf963ba42e39903ad03d6e3d94a.png");
          }
          .accessory-img {
            background-image: url("http://image.matchupexpo.com/1d0753b91d8a40208dc6b02624edf459.png");
          }
          .others-img {
            background-image: url("http://image.matchupexpo.com/c9e7baa57fdb40f1990e7646eef47c3e.png");
          }
        }
      }
      .circular-box {
        height: 620px;
        background: url("http://image.matchupexpo.com/81b9154f7bfb48ceb739724817851ac1.png")
          no-repeat;
        text-align: center;
        img {
          cursor: pointer;
        }
      }
    }
    .ty-img {
      width: 100%;
    }
    .flex-box {
      // width: 600px;
      height: 500px;
      display: flex;
      justify-content: center;
      margin: 25px auto 50px auto;
      .flex-l {
        width: 888px;
        height: 100%;
        .video-size {
          width: 100%;
          height: 100%;
        }
      }
      .flex-r {
        height: 100%;
        padding: 0 5px 0 15px;
        overflow-y: auto;
        overflow: hidden;
        li {
          // width: 285px;
          // height: 160px;
          // background: plum;
          // margin-bottom: 5px;
          border: 3px solid #02247a;
          border-radius: 10px;
          &:hover {
            cursor: pointer;
          }
          &[active] {
            border: 3px solid #f4483a;
            border-radius: 10px;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
          }
        }
      }
    }
    // .flex-box {
    //   width: 1200px;
    //   height: 592px;
    //   display: flex;
    //   justify-content: center;
    //   margin: 25px auto 50px auto;
    //   .flex-l {
    //     width: 880px;
    //     height: 100%;
    //     .video-size {
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }
    //   .flex-r {
    //     height: 100%;
    //     padding: 0 5px 0 15px;
    //     overflow-y: auto;
    //     li {
    //       // background: plum;
    //       // margin-bottom: 5px;
    //       border: 3px solid #0f0b3e;
    //       border-radius: 10px;
    //       &:hover {
    //         cursor: pointer;
    //       }
    //       &[active] {
    //         border: 3px solid #f4483a;
    //         border-radius: 10px;
    //       }
    //       img {
    //         width: 274px;
    //         height: 190px;
    //       }
    //     }
    //   }
    // }
    .product-box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 25px;
      .ty-box {
        width: 595px;
        // height: 440px;
        background: #ffffff;
        border-radius: 10px;
        padding: 20px 10px;
        color: #000;
        margin-bottom: 30px;
        .ty-top-title {
          line-height: 20px;
          font-size: 20px;
          border-left: 2px solid #f4483a;
          padding-left: 5px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .more-tit {
          text-align: right;
          color: #f14940;
          font-size: 16px;
        }
        .carousel-box {
          // height: 335px;
          .ul-box {
            margin-top: 10px;
            display: flex !important;
            justify-content: space-between;
            .ul-item {
              width: 187px;
              height: 335px;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      .three-box {
        width: 100%;
        height: 300px;
        background: #fff;
        border-radius: 10px;
        padding: 0 10px;
        margin-bottom: 90px;
        .three-title {
          line-height: 20px;
          font-size: 20px;
          border-left: 2px solid #f4483a;
          padding-left: 5px;
          font-weight: bold;
          margin: 20px 0 40px 0px;
        }
        .three-carousel {
          .img-list {
            display: flex !important;
            justify-content: space-between;
            img {
              cursor: pointer;
            }
          }
        }
      }
    }
    .enterprises-box {
      width: 1200px;
      height: 300px;
      margin: 50px auto 80px;
      background: rgba(77, 162, 217, 0.17);
      border: 2px solid #52abe5;
      border-radius: 10px;
      .enterprisesData-list {
        height: 300px;
        padding: 0 25px;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
      }
    }
    // .sign-up {
    //   width: 1200px;
    //   margin: 0 auto;
    //   color: #fff;
    //   padding-top: 30px;
    //   .sign-title {
    //     font-size: 24px;
    //     font-weight: bold;
    //     padding-left: 35px;
    //   }
    //   .sign-modal {
    //     padding: 30px 45px;
    //     background: rgba(77, 162, 217, 0.17);
    //     border: 2px solid #52abe5;
    //     border-radius: 10px;
    //     margin: 15px 0 50px 0;
    //     /deep/.ant-form-item-label > label {
    //       color: #fff;
    //       font-size: 18px;
    //     }
    //     /deep/.ant-select-selection--single {
    //       height: 55px;
    //       background: #02237a;
    //       border: none;
    //       color: #fff;
    //     }
    //     /deep/.ant-select-selection__rendered {
    //       line-height: 55px;
    //     }
    //     /deep/.ant-select-arrow {
    //       color: #fff;
    //       font-size: 20px;
    //     }
    //     /deep/.ant-input {
    //       height: 55px;
    //       background: #02237a;
    //       border: none;
    //       color: #fff;
    //     }
    //     .phone {
    //       /deep/.ant-input {
    //         height: 55px;
    //         background: #02237a;
    //         border: 2px solid #09c9f2;
    //         color: #fff;
    //       }
    //       /deep/.ant-input-group-addon {
    //         background: #02237a;
    //         border: 2px solid #09c9f2;
    //         border-right: none;
    //         color: #fff;
    //         padding: 0px 30px;
    //         font-size: 18px;
    //       }
    //     }
    //   }
    //   .classification {
    //     // height: 270px;
    //     padding: 35px 80px;
    //     background: rgba(77, 162, 217, 0.17);
    //     border: 2px solid #52abe5;
    //     border-radius: 10px;
    //     margin: 40px 0;
    //     /deep/.ant-checkbox-group {
    //       width: 100%;
    //     }
    //     /deep/.ant-checkbox-wrapper {
    //       width: 50%;
    //       color: #fff;
    //       margin: 5px 0;
    //     }
    //   }
    //   .sign-btn {
    //     width: 500px;
    //     height: 50px;
    //     line-height: 50px;
    //     background: #09c9f2;
    //     border-radius: 25px;
    //     margin: 0 auto;
    //     text-align: center;
    //     font-size: 22px;
    //   }
    //   .support-tit {
    //     font-size: 20px;
    //     margin: 65px 0 40px;
    //     padding-left: 80px;
    //   }
    //   .support-box {
    //     display: flex;
    //     flex-wrap: wrap;
    //     width: 900px;
    //     margin: 0 auto;
    //     justify-content: space-between;
    //     li {
    //       width: 50%;
    //       display: flex;
    //       align-items: center;
    //       margin-bottom: 40px;
    //       .bot-tit {
    //         // max-width: 170px;
    //         margin-left: 15px;
    //       }
    //     }
    //   }
    // }
  }
}
// ::-webkit-scrollbar {
//   width: 4px;
//   height: 7px;
// }
// ::-webkit-scrollbar-thumb {
//   border-radius: 3px;
//   -moz-border-radius: 3px;
//   -webkit-border-radius: 3px;
//   background-color: #f4483a;
// }
</style>

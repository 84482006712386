<template>
  <div>
    <a-modal
      v-model="visible"
      on-ok="handleOk"
      :footer="null"
      :centered="true"
      :width="1150"
      @cancel="handleCancel"
    >
      <div class="con-box">
        <img
          src="http://image.matchupexpo.com/efdb9997e5254e4397fad3c039174d62.png"
          alt=""
          @click="handleCancel"
          class="top-img"
        />
        <div class="top-flex">
          <p class="top-tit">{{ spanish.title }}</p>
        </div>
        <div class="conent-box">
          <p>
            {{ spanish.conName1 }}
          </p>
          <p>
            {{ spanish.conName2 }}
          </p>
          <p style="padding-left: 20px; color: #308fdf">
            {{ spanish.conName3 }} {{ this.tipsPhoneNumber }}
          </p>
          <p style="padding-left: 20px; color: #308fdf">
            {{ spanish.conName4 }} 123456
          </p>
          <p style="color: #308fdf">Tips:</p>
          <p style="padding-left: 20px">
            {{ spanish.conName5 }}
            <span style="color: #308fdf">{{ spanish.conName6 }}</span>
            {{ spanish.conName7 }} <br />
            <span style="padding-left: 16px">{{ spanish.conName8 }}</span>
          </p>
          <p style="text-align: center;">
            <img
              src="http://image.matchupexpo.com/a8625bf5b062453783283412019dcad5.png"
              alt=""
            />
          </p>
          <p style="padding-left: 16px">
            {{ spanish.conName9 }}
          </p>
          <p>
            <a
              href="https://image.matchupexpo.com/8eac6531684447fcac7cf80f4a90ce78.mp4"
              target="_blank"
              >https://image.matchupexpo.com/8eac6531684447fcac7cf80f4a90ce78.mp4</a
            >
          </p>
        </div>
        <a href="https://buyer.matchupexpo.com/home" target="_blank">
          <p class="bot-bac">{{spanish.btn}}</p>
        </a>
        <p class="drop-down">
          <a-button :type="isPrimary ? 'primary' : ''" @click="changeItem">
           Español
          </a-button>
          <a-button :type="!isPrimary ? 'primary' : ''" @click="changeItem" style="margin-left:20px"
            >português</a-button
          >
        </p>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    isSuccessfully: {
      type: Boolean,
      default: true,
    },
    tipsPhoneNumber: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isPrimary: true,
      visible: this.isSuccessfully,
      isChange: false,
      languageName: "",
      language: [
        {
          id: 1,
          name: "Español",
        },
        {
          id: 2,
          name: "葡语",
        },
      ],
      spanish: {},
      infoName: {
        title: "Has registrado con éxito en la exposición",
        conName1:
          "Has registrado con éxito en la Exposición Digital de Comercio Internacional Sudamérica-China 2021.",
        conName2:
          "Haz clic “Centro de Miembros”, y comunica con los proveedores ahora.",
        conName3: "Tu cuenta:",
        conName4: "Tu contraseña:",
        tips: "Tips:",
        conName5: "1. Agrega Whatsapp:",
        conName6: "+86 153 6610 6368",
        conName7:
          "y logra la guía para comprador. Escanea este código QR con la",
        conName8: "cámara de WhatsApp",
        conName9: "2. Haz clic con este link para ver la guía de exposición:",
        btn:'Iniciar sesión en el Centro de miembros'
      },
      infoNameEn: {
        title: "Você se inscreveu com sucesso na exposição",
        conName1: "Você se inscreveu com sucesso na 2021 South America-China International Trade Digital Expo.",
        conName2: "Clique em 'Member Center' e comunique-se com os fornecedores agora.",
        conName3: "Sua conta:",
        conName4: "Sua senha:",
        tips: "Pontas:",
        conName5: "1. Adicione o WhatsApp:",
        conName6: "+86 153 6610 6368",
        conName7:
          "e obtenha o guia do comprador. ",
        conName8: "Leia este código QR com a câmera WhatsApp",
        conName9: "2. Clique neste link para ver o guia da exposição:",
        btn:'Faça login na Central de Sócios'
      },
    };
  },
  watch: {
    isSuccessfully(val) {
      this.visible = val;
    },
  },
  methods: {
    changeItem() {
      this.isPrimary = !this.isPrimary;
      if (this.isPrimary) {
        this.spanish = this.infoName
      }else{
        this.spanish = this.infoNameEn
      }
    },
    itemClick(val) {
      this.languageName = val.name;
    },
    handleCancel() {
      this.$emit("successfully");
      // this.visible = false;
      // console.log(e);
    },
  },
  created() {
    this.spanish = this.infoName
  },
};
</script>
<style lang="scss" scoped>
p {
  margin-bottom: 5px;
}
.bot-bac {
  width: 487px;
  height: 72px;
  line-height: 56px;
  background: url("http://image.matchupexpo.com/7588fe6148124ad696e8af6f2dc81313.png");
  margin: 10px auto auto;
  color: #fff;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
}
.con-box {
  // padding-left: 100px;
  height: 800px;
  // overflow-y: auto;
  text-align: center;
  position: relative;
  .top-flex {
    text-align: center;
    position: relative;
    margin-top: 30px;
    .top-tit {
      font-size: 30px;
      color: #3691e0;
      font-weight: bold;
    }
    .top-tit1 {
      position: absolute;
      top: 5px;
      right: 210px;
      color: #3691e0;
      cursor: pointer;
      font-size: 22px;
    }
  }
  .top-img {
    width: 150px;
  }
  .conent-box {
    width: 100%;
    text-align: left;
    font-size: 18px;
    color: #afafaf;
    padding: 40px 95px 0 95px;
  }
  .tit1 {
    font-size: 34px;
    font-weight: bold;
    color: #ff4d4f;
  }
  .tit2 {
    font-size: 18px;
    font-weight: bold;
    // color: #ffffff;
    margin: 45px 0 80px 0;
  }
  .tit3 {
    width: 268px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #ff4d4f;
    border-radius: 4px;
    // color: #ffffff;
    margin: 0 auto 30px;
  }
  .drop-down {
    position: absolute;
    top: 30px;
    right: 150px;
    font-size: 20px;
  }
}
// /deep/.ant-modal-content {
//   // background: #0a082b;
//   // color: #fff;
// }
/deep/.ant-modal-close-x {
  color: #ababab;
  background: #fafafa;
  border-radius: 50%;
  font-size: 36px;
  margin: 24px 54px 0 0;
}
::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color: #ababab;
}
</style>

<template>
  <div>
    <a-modal
      v-model="visible"
      :dialog-style="{ top: '50px', right: '0px' }"
      :footer="null"
      :width="400"
      @cancel="handleCancel"
    >
      <div class="con-box">
        <p class="title-tit">Sign in as a BUYER</p>
        <div class="modal-box">
          <a-form-model :model="loginForm" ref="loginForm" :rules="modalRules">
            <a-form-model-item
              label="Mobile Number/Email"
              v-if="isPassword"
              prop="phoneNumberOrEmail"
            >
              <a-input
                v-model="loginForm.phoneNumberOrEmail"
                oninput="value=value.replace(/^\s+/,'')"
              />
            </a-form-model-item>
            <a-form-model-item
              label="Password"
              v-if="isPassword"
              prop="password"
            >
              <a-input
                  type="password"
                v-model="loginForm.password"
                oninput="value=value.replace(/^\s+/,'')"
              />
            </a-form-model-item>
            <a-form-model-item
              label="Mobile Number"
              v-if="!isPassword"
              prop="phoneNumberOrEmail"
            >
              <a-input
                v-model="loginForm.phoneNumberOrEmail"
                oninput="value=value.replace(/^\s+/,'')"
              />
            </a-form-model-item>
            <a-form-model-item label="Verification Code" v-if="!isPassword">
              <a-input-search
                oninput="value=value.replace(/^\s+/,'')"
                size="default"
                placeholder="Please input"
                v-model="loginForm.verifyCode"
                @search="getCode"
              >
                <a-button
                  v-if="sending"
                  slot="enterButton"
                  class="code-btn"
                  :disabled="disabled"
                  >Get Verification Code</a-button
                >
                <a-button
                  class="code-btn"
                  v-else
                  slot="enterButton"
                  :disabled="disabled"
                >
                  <span style="font-size: 12px"
                    >Resend after {{ second }} S</span
                  >
                </a-button>
              </a-input-search>
            </a-form-model-item>
            <p class="change-tit" @click="changeTit">
              {{
                isPassword
                  ? "Login by account password"
                  : "Login by verification code"
              }}
            </p>
            <a-button
              class="sign-in"
              type="danger"
              block
              @click="signClick"
              :loading="submitBtn"
              >Sign in</a-button
            >
            <p class="join-tit">
              New User?
              <span style="color: #2192d9; cursor: pointer" @click="JoinClick"
                >Join</span
              >
            </p>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { loginSendPhoneVerifyPC } from "@/api/user";
export default {
  props: {
    isLogin: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isPassword: true,
      loading: false,
      visible: this.isShow,
      loginForm: {
        phoneNumberOrEmail: "",
        password: "",
        verifyCode: "",
      },
      // 验证规则
      modalRules: {
        phoneNumberOrEmail: [
          {
            required: true,
            message: "Please fill in the phoneNumber",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please fill in the password",
            trigger: "blur",
          },
        ],
      },
      sending: true, //是否发送验证码
      disabled: false, //是否禁发验证码
      second: 60, //倒计时间
      submitBtn: false,
    };
  },
  watch: {
    isLogin(val) {
      this.visible = val;
    },
  },
  methods: {
    changeTit() {
      this.isPassword = !this.isPassword;
      this.$refs.loginForm.clearValidate();
    },
    handleCancel() {
      this.$emit("close");
    },
    JoinClick() {
      this.$emit("JoinClick");
    },
    signClick() {
      this.$refs.loginForm.validate((valid) => {
        this.submitBtn = true;
        this.$store
          .dispatch(
            this.isPassword ? "buyerloginBypass" : "buyerloginBycode",
            this.loginForm
          )
          .then(({ data }) => {
            this.$message.success("Login successfully", 2, () => {
              this.submitBtn = false;
              this.$emit("close");
            });
          })
          .catch((err) => {
            this.submitBtn = false;
          });
      });
    },
    getCode() {
      if (!this.sending) return;
      this.$refs.loginForm.validateField("phoneNumberOrEmail", (valid) => {
        console.log(valid);
        if (!valid) {
          loginSendPhoneVerifyPC({
            phoneNumberOrEmail: this.loginForm.phoneNumberOrEmail,
          }).then((data) => {
            this.sending = false;
            this.disabled = true;
            this.timeDown();
            this.$message.success("Send Succseefully");
          });
        }
      });
    },
    timeDown() {
      let result = setInterval(() => {
        --this.second;
        if (this.second < 0) {
          clearInterval(result);
          this.sending = true;
          this.disabled = false;
          this.second = 60;
        }
      }, 1000);
    },
  },
  mounted() {
    let that = this;
    document.onkeypress = function (e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.signClick(); // 登录方法名
        return false;
      }
    };
  },
  created() {},
};
</script>
<style lang="scss" scoped>
p {
  margin: 0;
}
/deep/.ant-modal-body {
  padding: 0;
}
.con-box {
  /deep/.ant-modal-close {
    top: -10px;
    right: -10px;
  }
  .title-tit {
    padding: 10px 0 10px 30px;
    border-bottom: 1px solid #e6e6e6;
    font-size: 30px;
    font-weight: bold;
    color: #f14940;
  }
  .modal-box {
    padding: 25px;
    /deep/.ant-form-item {
      margin: 0;
    }
    .change-tit {
      font-size: 14px;
      color: #2192d9;
      cursor: pointer;
    }
    .sign-in {
      height: 32px;
      line-height: 32px;
      background: #ff4d4f;
      border-radius: 4px;
      font-size: 14px;
      text-align: center;
      color: #fff;
      margin-top: 30px;
    }
    .join-tit {
      text-align: right;
      margin-top: 10px;
      font-size: 14px;
    }
  }
}
</style>
